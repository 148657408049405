import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { clearLocalStorage } from '@/auth/utils'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401) {
          if (this.isAlreadyFetchingAccessToken) {
            clearLocalStorage()
            // eslint-disable-next-line no-restricted-globals
            location.reload()
          } else {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.access)
              this.setRefreshToken(r.data.refresh)

              this.onAccessTokenFetched(r.data.access)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    })
  }

  createGateway(...args) {
    return this.axiosIns.post(`${this.jwtConfig.gatewayEndpoint}/`, ...args)
  }

  updateGateway(...args) {
    return this.axiosIns.patch(`${this.jwtConfig.gatewayEndpoint}/`, ...args)
  }

  getGateways(...args) {
    return this.axiosIns.get(`${this.jwtConfig.gatewayEndpoint}/`, ...args)
  }

  deleteGateway(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.gatewayEndpoint}/${uuid}`)
  }

  getNodes(type) {
    return this.axiosIns.get(`${this.jwtConfig.nodeEndpoint}/${type}`)
  }

  createNode(...args) {
    return this.axiosIns.post(this.jwtConfig.nodeEndpoint, ...args)
  }

  updateNode(...args) {
    return this.axiosIns.patch(this.jwtConfig.nodeEndpoint, ...args)
  }

  deleteNode(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.nodeEndpoint}/${uuid}`)
  }

  bulkDeleteNode(...args) {
    return this.axiosIns.post(`${this.jwtConfig.nodeEndpoint}/bulk-delete`, ...args)
  }

  connectNodesWithGateway(...args) {
    return this.axiosIns.post(`${this.jwtConfig.gatewayEndpoint}/connect`, ...args)
  }

  connectWithWorkflow(...args) {
    return this.axiosIns.post(`${this.jwtConfig.workflowEndpoint}/connect`, ...args)
  }

  connectWithConditionals(...args) {
    return this.axiosIns.post(`${this.jwtConfig.conditionalEndpoint}/connect`, ...args)
  }

  connectNodesWithBranch(...args) {
    return this.axiosIns.post(`${this.jwtConfig.branchEndpoint}/connect`, ...args)
  }

  connectNodesWithJoin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.joinEndpoint}/connect`, ...args)
  }

  disconnectNodes(...args) {
    return this.axiosIns.post(`${this.jwtConfig.nodeEndpoint}/disconnect`, ...args)
  }

  getNodeByType(arg) {
    return this.axiosIns.get(`${this.jwtConfig.nodeEndpoint}/type/${arg}`)
  }

  shareNode(args) {
    return this.axiosIns.post(`${this.jwtConfig.workflowEndpoint}/share`, args)
  }

  revokeNodeSharing(args) {
    return this.axiosIns.post(`${this.jwtConfig.workflowEndpoint}/revoke-share`, args)
  }

  getSharedNodes(...args) {
    return this.axiosIns.get(`${this.jwtConfig.workflowEndpoint}/shared`, ...args)
  }

  // eslint-disable-next-line class-methods-use-this
  getChatNodeApi(path, args, headers) {
    return axios.post(`${process.env.VUE_APP_DBAI_API}/v1/${path}`, args, headers)
  }

  getGatewayRoomId(gateway) {
    return this.axiosIns.get(`${this.jwtConfig.wsEndpoint}/${gateway}`)
  }

  // Services
  getServices() {
    return this.axiosIns.get(`${this.jwtConfig.servicesEndpoint}/`)
  }

  storeService(args) {
    return this.axiosIns.post(`${this.jwtConfig.servicesEndpoint}/`, args)
  }

  updateService(args) {
    return this.axiosIns.patch(`${this.jwtConfig.servicesEndpoint}/`, args)
  }

  // User
  getUsersList() {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/`)
  }

  storeUser(args) {
    return this.axiosIns.post(`${this.jwtConfig.usersEndpoint}/`, args)
  }

  updateUser(id, args) {
    return this.axiosIns.patch(`${this.jwtConfig.usersEndpoint}/${id}`, args)
  }

  addUserService(args) {
    return this.axiosIns.post(`${this.jwtConfig.usersEndpoint}/services`, args)
  }
}
